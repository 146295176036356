<template lang="pug">
div
  DataFilter(
    :filterProps="filterProps"
    :items="dados"
    :paginate="false"
  )
    template( v-slot:default="{ items }" )
      v-data-table(
        dense
        show-expand
        single-expand
        disable-pagination
        hide-default-footer
        item-key="id"
        :headers="dataTableHeaders"
        :items="items"
      )
        template( v-slot:item="{ item, headers, isExpanded, expand }" )
          tr
            td(
              v-for="(row, index) in headers"
              class="pa-0 ma-0"
              @click="expand(!isExpanded)"
              :data-open="isExpanded" style=" cursor: pointer"
            )
              div(
                v-if="row.value === 'cliente'"
                v-bind:class="getPriorityByDate(item.datahorasincronizado)"
                style="{ width: 100%; height: 100%; }"
              )
                div( class="ml-1" ) {{ item.cliente }}

              div(
                v-if="row.value === 'datahorasincronizado'"
                v-bind:class="getPriorityByDate(item.datahorasincronizado)"
                style="{ width: 100%; height: 100%; }"
              )
                div( class="ml-1" ) {{ item.nome }}

              div(
                v-if="row.value === 'sysatualizadorativo'"
                v-bind:class="item.sysatualizadorativo ? 'dv-status-ativo' : 'dv-status-inativo'"
                style="{ width: 100%; height: 100%; }"
              )
                div( class="ml-1" ) {{ item.sysatualizadorativo ? 'Ativo' : 'Desativado' }}

              div(
                v-if="row.value === 'sysbackupativo'"
                v-bind:class="item.sysbackupativo ? 'dv-status-ativo' : 'dv-status-inativo'"
                style="{ width: 100%; height: 100%; }"
              )
                div( class="ml-1" ) {{ item.sysbackupativo ? 'Ativo' : 'Desativado' }}

              div(
                v-if="row.value === 'utilizaemissor'"
                v-bind:class="item.utilizaemissor ? 'dv-status-ativo' : 'dv-status-inativo'"
                style="{ width: 100%; height: 100%; }"
              )
                div( class="ml-1" ) {{ item.utilizaemissor ? 'Ativo' : 'Desativado' }}

              div(
                v-if="row.value === 'emissorativo'"
                v-bind:class="item.emissorativo ? 'dv-status-ativo' : 'dv-status-inativo'"
                style="{ width: 100%; height: 100%; }"
              )
                div( class="ml-1" ) {{ item.emissorativo ? 'Ativo' : 'Desativado' }}

              div(
                v-if="row.value === 'utilizaconnect'"
                v-bind:class="item.utilizaconnect ? 'dv-status-ativo' : 'dv-status-inativo'"
                style="{ width: 100%; height: 100%; }"
              )
                div( class="ml-1" ) {{ item.utilizaconnect ? 'Ativo' : 'Desativado' }}

              div(
                v-if="row.value === 'connectativo'"
                v-bind:class="item.connectativo ? 'dv-status-ativo' : 'dv-status-inativo'"
                style="{ width: 100%; height: 100%; }"
              )
                div( class="ml-1" ) {{ item.connectativo ? 'Ativo' : 'Desativado' }}

              div( v-if="row.value === 'data-table-expand'" align="center" )
                v-icon( small ) {{ isExpanded ? 'fa-chevron-up' : 'fa-chevron-down' }}

        template( v-slot:expanded-item="{ headers, item }" )
          tr
            td
              b
                div( class="ml-1 mr-1" )
                  div Hora da sincronia: {{ formatDateTime(item.datahorasincronizado) }}
                  div Hora da máquina: {{ formatDateTime(item.datahoraservidor) }} GMT-{{ item.datahoraservidor.split('-')[3] }}
                  div Versão da base: {{ item.versaobase }}
                  div Versão do server: {{ item.versaoserver }}
                  div Sistema operacional: {{ item.versaosistemaoperacional }}
                  div ID Servidor: {{ item.idservidor }}
                  div Uptime Servidor: {{ convertMinToInterval(item.uptimeservidor) }}
                  div Data/Hora inicio Server: {{ formatDateTime(item.datahorainicioserver) }}
                  div Memória em uso server: {{ bytesToMB(item.totalmemoriaserver) }}
                  div Host e porta FireBird: {{ item.hostfirebird }}:{{ item.portafirebird }}
                  div Versão do Firebird: {{ item.versaofirebird == null ? 'Não informado' : item.versaofirebird }}
                  div Caminho base: {{ item.caminhobase }}
                  div Tamanho base: {{ bytesToGB(item.tamanhobase) }}
                  div Data criação da base: {{ formatDateTime(item.datacriacaobase) }}
                  div Intervalo Sweep: {{ item.sweepintervalbase }}
                  div ODS Base: {{ item.odsbase }}
                  div Espaço livre na partição: {{ bytesToGB(item.espacolivreparticaobase) }} / {{ bytesToGB(item.tamanhoparticaobase) }}

            td
              b <i>Atualização {{ item.sysatualizadorativo ? 'Ativo' : 'Desativada' }}</i>
                div( class="ml-1 mr-1"  v-if="item.sysatualizadorativo" )
                  div Versão: {{ item.sysatualizadorversao }}
                  div Quando: {{ formatDateTime(item.sysatualizadordatahoraatualizacao) }}
                  div Status: {{ item.sysatualizadorstatus }}

            td
              b <i>Backup {{ item.sysbackupativo ? 'Ativo' : 'Desativado' }}</i>
                div( class="ml-1 mr-1" v-if="item.sysbackupativo" )
                  div Inicio: {{ item.sysbackuphorarioinicial }}
                  div Fim: {{ item.sysbackuphorariofinal }}
                  div Intervalo: {{ item.sysbackupintervalo }}
                  div Dt. Hr. comp.: {{ formatDateTime(item.sysbackupdatahoracompleto) }}
                  div Dt. Hr. inc.: {{ formatDateTime(item.sysbackupdatahoraincremental) }}

</template>

<script>
import { mapMutations } from 'vuex';
import { server } from '@/api';

export default {
  data: () => ({
    dataTableHeaders: [
      { value: 'cliente', text: 'ID', width: '70' },
      { value: 'datahorasincronizado', text: 'Server', width: '400' },
      { value: 'sysatualizadorativo', text: 'Atualizador', width: '400' },
      { value: 'sysbackupativo', text: 'SySBackup' },
      { value: 'utilizaemissor', text: 'Usa Emissor' },
      { value: 'emissorativo', text: 'Emissor ativo' },
      { value: 'utilizaconnect', text: 'Usa CS Connect' },
      { value: 'connectativo', text: 'CS Connect ativo' },
      { text: '', value: 'data-table-expand' },
    ],

    filterProps: {
      includeSearch: true,
      items: [
        {
          text: 'Server',
          type: 'select',
          values: [
            {
              field: 'datahorasincronizado',
              text: 'Sinc. a menos de 15 min.',
              fn: ([item]) => Math.round(((new Date() - new Date(item.datahorasincronizado)) / 1000) / 60) <= 15,
            },
            {
              field: 'datahorasincronizado',
              text: 'Sinc. a mais de 15 min.',
              fn: ([item]) => {
                const diff = Math.round(((new Date() - new Date(item.datahorasincronizado)) / 1000) / 60);
                return ((diff > 15) && (diff <= 30));
              },
            },
            {
              field: 'datahorasincronizado',
              text: 'Sinc. a mais de 30 min.',
              fn: ([item]) => Math.round(((new Date() - new Date(item.datahorasincronizado)) / 1000) / 60) > 30,
            },
          ],
        },
        {
          text: 'Atualizador',
          type: 'select',
          values: [
            { field: 'sysatualizadorativo', text: 'Ativo', value: 1 },
            { field: 'sysatualizadorativo', text: 'Inativo', value: 0 },
          ],
        },
        {
          text: 'SySBackup',
          type: 'select',
          values: [
            { field: 'sysbackupativo', text: 'Ativo', value: 1 },
            { field: 'sysbackupativo', text: 'Inativo', value: 0 },
          ],
        },
        {
          text: 'Emissor',
          type: 'select',
          values: [
            {
              text: 'Usa',
              fn: ([item]) => item.utilizaemissor,
            },
            {
              text: 'Não usa',
              fn: ([item]) => !item.utilizaemissor,
            },
            {
              text: 'Usa e ativo',
              fn: ([item]) => item.utilizaemissor && item.emissorativo,
            },
            {
              text: 'Usa e inativo',
              fn: ([item]) => item.utilizaemissor && !item.emissorativo,
            },
          ],
        },
        {
          text: 'CS Connect',
          type: 'select',
          values: [
            {
              text: 'Usa',
              fn: ([item]) => item.utilizaconnect,
            },
            {
              text: 'Não usa',
              fn: ([item]) => !item.utilizaconnect,
            },
            {
              text: 'Usa e ativo',
              fn: ([item]) => item.utilizaconnect && item.connectativo,
            },
            {
              text: 'Usa e inativo',
              fn: ([item]) => item.utilizaconnect && !item.connectativo,
            },
          ],
        },
      ],
    },

    dados: [],
    interval: null,
    search: null,
    tab: null,
    hash: null,
  }),

  created() {
    window.addEventListener('resize', () => {
      if (document.getElementsByClassName('dv-status-base')[0]) { document.getElementsByClassName('dv-status-base')[0].style.columnCount = this.getColumnCount(); }
    });
  },

  async mounted() {
    this.isControl(true);
    this.setTitle('Status do CSServer por cliente');
    await this.getStatus();
    this.interval = setInterval(async () => await this.getStatus(), 30 * 1000); // 30 secs
  },

  destroyed() {
    clearInterval(this.interval);
  },

  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    convertMinToInterval(time) {
      if (!time || isNaN(time)) return;

      const days = Math.floor(time / 1440);
      const hours = Math.floor((time - (days * 1440)) / 60);
      const minutes = time - (days * 1440) - (hours * 60);

      return `${days} dia(s), ${hours} hora(s), ${minutes} minuto(s)`;
    },

    getColumnCount() {
      const viewportWidth = window.innerWidth;
      const columnWidth = 255;
      return Math.floor(viewportWidth / columnWidth);
    },

    async getStatus() {
      server.refreshAuthProd(this.$store.state.auth);
      this.dados = await server.getStatus();
    },

    formatDateTime(value) {
      if (!value) return;

      const arr = value.split('T');
      const result = `${arr[0].split('-').reverse().join('/')} ${arr[1].split('.')[0]}`;
      return result;
    },

    getPriorityByDate(value) {
      const diff = Math.round(((new Date() - new Date(value)) / 1000) / 60);

      if (diff >= 30) { return 'dv-status-inativo'; }

      if (diff > 15) { return 'dv-status-alerta'; }

      return 'dv-status-ativo';
    },

    setHashdetail(value) {
      if (this.hash === value) { return this.hash = null; }

      this.hash = value;
    },

    bytesToGB(value) {
      if (!value || isNaN(value)) return;

      const result = (value / 1024 / 1024 / 1024).toFixed(2);
      return `${result} GB`;
    },

    bytesToMB(value) {
      if (!value || isNaN(value)) return;

      const result = (value / 1024 / 1024).toFixed(2);
      return `${result} MB`;
    },
  },
};
</script>

<style>

.dv-status-title {
  background-color: #23c5ea;
}

.dv-status-inativo {
  background-color: #ff8080;
}

.dv-status-alerta {
  background-color: #f1ed68
}

.dv-status-ativo {
  background-color: #50fa7b
}

</style>
